import React from "react";
import { Row, Col } from "react-bootstrap";
import { format } from "date-fns";
import { es } from "date-fns/locale";
import SubclassItem from "./subclassItem";
const ClassList = ({ classes }) => {
  const getMonth = (idx) => {
    return format(new Date(new Date().getFullYear(), idx), "MMMM - yyyy", {
      locale: es,
    }).toUpperCase();
  };

  const getMonthNextYear = (idx) => {
    return format(new Date(new Date().getFullYear() + 1, idx), "MMMM - yyyy", {
      locale: es,
    }).toUpperCase();
  };
  return (
    <Row>
      {classes && (
        <Col>
          <Row>
            <Col xs={12}>
              <h2 style={{ marginTop: "10px" }}>Clases de este mes</h2>
              {[...Array(12 - new Date().getMonth())].map((_, idx) => {
                if (
                  classes
                    .filter((e) => !e.isTrainee)
                    .map((o) => o.subclasses)
                    .flat()
                    .filter(
                      (e) =>
                        new Date(e.date).getMonth() ===
                          new Date().getMonth() + idx &&
                        new Date(e.date).getFullYear() ===
                          new Date().getFullYear()
                    ).length === 0
                )
                  return <div key={idx}></div>;
                return (
                  <Row key={idx}>
                    <Col xs={12}>
                      <Row>
                        <Col>
                          <h4
                            class="mainColorText"
                            style={{ marginTop: "20px" }}
                          >
                            {getMonth(new Date().getMonth() + idx)}
                          </h4>
                        </Col>
                      </Row>
                      {classes
                        .filter((e) => !e.isTrainee)
                        .map((o) => o.subclasses)
                        .flat()
                        .filter(
                          (e) =>
                            new Date(e.date).getMonth() ===
                              new Date().getMonth() + idx &&
                            new Date(e.date).getFullYear() ===
                              new Date().getFullYear()
                        )
                        .sort((a, b) => new Date(a.date) - new Date(b.date))
                        .map((subclass, subclassIdx) => {
                          return (
                            <SubclassItem
                              key={subclassIdx}
                              subclass={subclass}
                            />
                          );
                        })}
                    </Col>
                  </Row>
                );
              })}
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <h2 style={{ marginTop: "20px" }}>Todas las clases del año</h2>
              {[...Array(12)].map((_, idx) => {
                if (
                  classes
                    .filter((e) => !e.isTrainee)
                    .map((o) => o.subclasses)
                    .flat()
                    .filter(
                      (e) =>
                        new Date(e.date).getMonth() === idx &&
                        new Date(e.date).getFullYear() ===
                          new Date().getFullYear()
                    ).length === 0
                )
                  return <div key={idx}></div>;
                return (
                  <Row key={idx}>
                    <Col xs={12}>
                      <Row>
                        <Col>
                          <h4
                            class="mainColorText"
                            style={{ marginTop: "20px" }}
                          >
                            {getMonth(idx)}
                          </h4>
                        </Col>
                      </Row>
                      {classes
                        .filter((e) => !e.isTrainee)
                        .map((o) => o.subclasses)
                        .flat()
                        .filter(
                          (e) =>
                            new Date(e.date).getMonth() === idx &&
                            new Date(e.date).getFullYear() ===
                              new Date().getFullYear()
                        )
                        .sort((a, b) => new Date(a.date) - new Date(b.date))
                        .map((subclass, subclassIdx) => {
                          return (
                            <SubclassItem
                              key={subclassIdx}
                              subclass={subclass}
                            />
                          );
                        })}
                    </Col>
                  </Row>
                );
              })}
            </Col>
          </Row>
        </Col>
      )}
    </Row>
  );
};

export default ClassList;
