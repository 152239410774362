import React from "react";
import { Switch, Route } from "react-router-dom";
import login from "../login/login";
import register from "../register/register";
import userpage from "../userpage";
import Landing from "./landing";
import userDetails from "../userpage/userDetails";
import classDetail from "../classes/classDetail";
import SubclassDetail from "../classes/subclassDetail";
import SuccessPayment from "../paymentCallbacks/successPayment";
import PaymentInfo from "./paymentInfo";
import ResetPassword from "./resetPassword";
import Library from "../library";
import LibraryClassDetail from "../library/libraryClassDetail";
import LibrarySubclassDetail from "../library/librarySubclassDetail";
import Follower from "./follower";
import Practicas from "../classes/practicas";
/* import Checkout from "../checkout"; */

const Membersite = () => {
  return (
    <Switch>
      <Route exact path="/membersite" component={Landing} />
      <Route exact path="/membersite/trainee" component={Follower} />
      <Route exact path="/membersite/login" component={login} />
      <Route exact path="/membersite/register" component={register} />
      <Route exact path="/membersite/user" component={userpage} />
      <Route exact path="/membersite/practicas" component={Practicas} />
      <Route exact path="/membersite/class/:id" component={classDetail} />
      <Route exact path="/membersite/subclass/:id" component={SubclassDetail} />
      <Route exact path="/membersite/profile" component={userDetails} />
      <Route exact path="/membersite/success" component={SuccessPayment} />
      <Route exact path="/membersite/payment-info" component={PaymentInfo} />
      <Route
        path="/membersite/library/:year/class/:id"
        component={LibraryClassDetail}
      />
      <Route
        path="/membersite/library/:year/subclass/:id"
        component={LibrarySubclassDetail}
      />
      <Route path="/membersite/library/:year" component={Library} />
      <Route
        exact
        path="/membersite/reset-password"
        component={ResetPassword}
      />
    </Switch>
  );
};

export default Membersite;
