import React from "react";
import { Row, Col, Container, Image } from "react-bootstrap";

//Images import
import lauraImage from "../../assets/images/laura3.png";

const MiCamino = () => {
  return (
    <Row className="bg-dark px-3">
      <Container>
        <br></br>
        <br />
        <Row>
          <Col>
            <Image src={lauraImage} fluid rounded />
          </Col>
        </Row>
        <Row>
          <Col>
            <br />
            <br />
            <h3 className="text-center secondaryFont mainColorText display-4">
              Mi camino
            </h3>
            <br />
            <br></br>
          </Col>
        </Row>
        <Row>
          <Col className="pr-5">
            <h3 className="text-white">
              Nos descubrimos a medida que vamos viviendo
            </h3>
            <br />
            <p className="text-light">
              Soy una mujer que busca compartir herramientas que experimenté y que
              permiten conectar la energía del plano sutil con este plano
              físico. Me moviliza mejorar la vida de las personas de forma
              simple.
            </p>
            <p className="text-light">
              Mi camino ha sido variado y nutrido, la vida me ha permitido
              profundizar en el aprendizaje del Reiki, Kabbalah, Diseño Humano,
              Decodificación Bioemocional, un Curso de Milagros, Coach y
              Neurociencias. A partir de lo aprendido diseñé un sistema llamado
              OSHER que aplico hace varios años en mis consultas, acompaño a las
              personas en su camino para alcanzar la felicidad. Estoy convencida
              que ser feliz es un derecho inherente del ser humano pero la
              decisión es de cada uno.
            </p>
            <p className="text-light">
              En el año 2012, un maestro swami llegó a Mendoza a poner la piedra
              fundacional en San Huberto. Allí se fundó un templo para honrar la
              energía del chacra corazón de la tierra, que se trasladó del
              Himalaya a Uspallata. En un instante de meditación él me miró a
              los ojos y me dijo: tu nombre es &quot;Lala&quot;.
            </p>
            <p className="text-light">
              Desde ese momento comprendí que mi espíritu está al servicio de
              las personas.
            </p>
          </Col>
        </Row>
      </Container>
    </Row>
  );
};

export default MiCamino;
