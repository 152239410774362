import React from "react";
import { Row, Col, Container } from "react-bootstrap";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Contacto = () => {
  return (
    <Row className="bg-dark">
      <Container>
        <Row>
          <Col>
            <br />
            <br />
            <h3 className="text-center secondaryFont mainColorText display-4">
              Reservas y consultas
            </h3>
            <br></br>
            <br></br>
          </Col>
        </Row>
        <Row>
          <Col className="text-center">
            <h3 className="text-white">Talleres y Clases</h3>
            <br />
            <p className="text-light">
              Todos los talleres son por cupos limitados, para mayor información
            </p>
            <p>
              <FontAwesomeIcon
                icon={faWhatsapp}
                color="#efba81"
                size="lg"
                className="mr-3"
              />
              <b className="mainColorText">
                +54 9 261 653-4815 - <u>cwillink@icloud.com</u>
              </b>
            </p>
          </Col>
          <Col className="text-center">
            <h3 className="text-white">Retiros y Consultas</h3>
            <br />
            <p className="text-light">
              Robles de Besares, Besares 796, Luján de Cuyo, Mendoza, Argentina
            </p>
            <p>
              <FontAwesomeIcon
                icon={faWhatsapp}
                color="#efba81"
                size="lg"
                className="mr-3"
              />
              <b className="mainColorText">
                +54 9 261 653-4815 - <u>www.roblesdebesares.com.ar</u>
              </b>
            </p>
          </Col>
        </Row>
      </Container>
    </Row>
  );
};

export default Contacto;
