import React, { useEffect } from "react";
import { Container, Row, Col, Button, NavLink } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Redirect } from "react-router-dom";

const LibrarySubclassDetail = (props) => {
  const { subclass } = props.location.state;
  const auth = useSelector((state) => state.firebaseState.auth);
  useEffect(() => {
    window.scrollTo(0, 0);
  });
  if (!auth.uid) return <Redirect to="/membersite/login"></Redirect>;
  console.log(subclass);
  return (
    <Container className="mt-5 pt-5">
      <Row>
        <Col>
          <h1>{subclass.title}</h1>
          <br />
          <div style={{ whiteSpace: "pre-wrap" }}>{subclass.description}</div>
          {subclass.meetingLink && (
            <NavLink rel="noopener" href={subclass.meetingLink} target="_blank">
              <Button className="mainButton mt-5">Ingresar a la Clase</Button>
            </NavLink>
          )}
          {subclass.audioLink && (
            <NavLink rel="noopener" href={subclass.audioLink} target="_blank">
              <Button className="mainButton mt-5">Escuchar Audio</Button>
            </NavLink>
          )}
        </Col>
        {subclass.link && (
          <Col>
            <iframe
              title={subclass.title}
              src={subclass.link}
              width="100%"
              height="300"
              frameBorder="0"
              allow="autoplay; fullscreen"
              allowFullscreen
            ></iframe>
          </Col>
        )}
      </Row>
    </Container>
  );
};

export default LibrarySubclassDetail;
