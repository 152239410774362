import { handleActions } from "redux-actions";

export const uiReducer = handleActions(
  {
    SNACKBAR_SUCCESS: (state, action) => {
      return {
        ...state,
        successSnackbarOpen: true,
        successSnackbarMessage: action.payload.message,
      };
    },
    SNACKBAR_CLEAR: (state, action) => {
      return {
        ...state,
        successSnackbarOpen: false,
        errorSnackbarOpen: false,
        infoSnackbarOpen: false,
      };
    },
  },
  {}
);
