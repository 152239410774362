import { handleActions } from "redux-actions";

export const classReducer = handleActions(
  {
    GET_CLASSES: (state, action) => {
      return {
        ...state,
        fetching: true,
      };
    },
    GET_CLASSES_FINISHED: (state, action) => {
      return {
        ...state,
        fetching: false,
        classes: action.payload.classes,
      };
    },
    GET_CLASSES_ERROR: (state, action) => {
      return {
        ...state,
        fetching: false,
        error: action.error,
      };
    },
  },
  {
    fetching: false,
    classes: [],
    error: null,
  }
);

export const getClasses = (state) => state.classState.classes;
export const getFetching = (state) => state.classState.fetching;
export const getError = (state) => state.classState.error;
