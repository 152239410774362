import React from "react";
import { Container } from "react-bootstrap";
import Courses from "../courses/index";
import Header from "./header";
/* import Osher from "./osher";
import Herramientas from "./herramientas"; */
import TeDelAlma from "./tedelalma";
import MiCamino from "./micamino";
import Contacto from "./contacto";
import LalaFooter from "../footer/footer";

import Testimonies from "./testimonies";
import Retiros from "./retiros";
import Robles from "./robles";

const Home = () => {
  return (
    <Container fluid>
      <Header
        text='"Te acompaño a conectar con tu corazón, a elevar tu vibración y así abrirte al amor."'
        buttonText="Membresía"
      />
      <br></br>
      <br></br>
      <Courses />
      <br></br>
      <br></br>
      <br></br>
      <Testimonies />
      <TeDelAlma />
      <Retiros />
      <Robles />
      <br />
      <MiCamino />
      <Contacto />
      <LalaFooter />
    </Container>
  );
};

export default Home;
