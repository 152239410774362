import React, { Component } from "react";
import { connect } from "react-redux";
import { Container, Row, Col, Alert, Button } from "react-bootstrap";
import { bindActionCreators } from "redux";
import LoadingPage from "../loadingpage";
import RetreatList from "./retreatList";
import { getRetreatsAction } from "../../actions/retreatActions";
import {
  getRetreats,
  getRetreatsError,
  getRetreatsFetching,
} from "../../reducers/retreatsReducer";

class Retreats extends Component {
  constructor(props) {
    super(props);
    this.shouldComponentRender = this.shouldComponentRender.bind(this);
  }

  componentWillMount() {
    const { getRetreats } = this.props;
    getRetreats();
  }

  shouldComponentRender() {
    const { fetching } = this.props;
    if (fetching === false) return false;
    return true;
  }

  render() {
    const { retreats, error } = this.props;

    if (this.shouldComponentRender()) return <LoadingPage />;
    return (
      <Container>
        <Row>
          <Col>
            <h3 className="text-center secondaryFont mainColorText display-4">
              Retiros
            </h3>
          </Col>
        </Row>
        <br />
        <br />
        {error && <Alert variant="danger">{error}</Alert>}
        <RetreatList retreats={retreats} />
        <br></br>
        <br></br>
      </Container>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    error: getRetreatsError(state),
    retreats: getRetreats(state),
    fetching: getRetreatsFetching(state),
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getRetreats: getRetreatsAction,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Retreats);
