import { handleActions } from "redux-actions";

export const coursesReducer = handleActions(
  {
    UPDATED_COURSE: (state, action) => {
      return {
        ...state,
        courses: [
          ...state.courses.filter(
            (element) => element._id !== action.payload._id
          ),
          action.payload,
        ],
      }
    },
    GET_COURSES: (state, action) => {
      return {
        ...state,
        fetching: true,
      };
    },
    GET_COURSES_FINISHED: (state, action) => {
      return {
        ...state,
        fetching: false,
        courses: action.payload.courses,
      };
    },
    GET_COURSES_ERROR: (state, action) => {
      return {
        ...state,
        fetching: false,
        error: action.error,
      };
    },
  },
  {
    fetching: false,
    courses: [],
    error: null,
  }
);

export const getCourses = (state) => state.courseState.courses;
export const getCoursesFetching = (state) => state.courseState.fetching;
export const getCoursesError = (state) => state.courseState.error;
