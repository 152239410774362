import React, { useEffect } from "react";
import { Container, Row, Col, Image } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { getCoursesAction } from "../../actions/courseActions";
import LoadingPage from "../loadingpage";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const CourseDetail = (props) => {
  const course = useSelector((state) =>
    state.courseState.courses.find(
      (element) => element._id === props.match.params.id
    )
  );
  const dispatch = useDispatch();
  useEffect(() => {
    window.scrollTo(0,0);
    if (!course) dispatch(getCoursesAction());
  }, [course, dispatch]);

  if (!course) return <LoadingPage />;
  return (
    <Container className="mt-5 pt-5">
      <Row>
        <Col>
          <h1 className="mainColorText">{course.title}</h1>
        </Col>
      </Row>
      <Row className="mt-5">
        <Col md={4}>
          <div>
            <Image
              src={course.imageUrl}
              fluid
              className="shadow"
              style={{ borderRadius: "7px" }}
            />
          </div>
        </Col>
        <Col md>
          <h2>{course.shortDescription}</h2>
          <br />
          <p style={{ whiteSpace: "pre-wrap" }}>{course.description}</p>
        </Col>
      </Row>
      <Row className="mt-3">
        <Col>
          <h4>
            Para más información sobre fechas y costos sobre este taller,
            comunicate por cualquiera de los siguientes medios.
          </h4>
          <br />
          <Row>
            <Col className="text-center">
              <a
                rel="noopener noreferrer"
                className="text-decoration-none"
                href={`https://api.whatsapp.com/send?phone=+5492616534815&text=%20Hola, quisiera recibir mas informacion sobre el curso ${course.title}`}
                target="_blank"
              >
                <FontAwesomeIcon icon={faWhatsapp} size="5x" color="#075e54" />
                <h6 style={{ color: "#075e54" }}>Enviar Whatsapp</h6>
              </a>
            </Col>
            <Col className="text-center">
              <a
                className="text-decoration-none"
                href={`mailto:cwillink@icloud.com?subject=Informacion%20sobre%20curso%20${course.title}`}
              >
                <FontAwesomeIcon
                  icon={faEnvelope}
                  size="5x"
                  className="mainColorText"
                />
                <h6 className="mainColorText">Enviar Correo</h6>
              </a>
            </Col>
          </Row>
        </Col>
      </Row>
      <br />
      <br />
    </Container>
  );
};

export default CourseDetail;
