import React from "react";
import { Row, Col } from "react-bootstrap";
import logo from "../../assets/images/logo.png";

const Phrase = () => {
  return (
    <Row style={{ backgroundColor: "#f1f1f1", padding: "40px 20px" }}>
      <Col className="text-center" md={{ span: 4, offset: 4 }}>
        <h1 className="title mainColorText">"Todo se trata de mi"</h1>
        <br></br>
        <p>
          Ya conoces esta frase que nos acompaña en cada encuentro y también en
          la agenda de cada año. Transitaremos esta experiencia juntos
        </p>
        <p>Gracias por acompañarme.</p>
        <br />
        <img
          src={logo}
          height="40"
          className="d-inline-block align-top"
          alt="logo"
        />
        <br />
      </Col>
    </Row>
  );
};

export default Phrase;
