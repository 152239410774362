import { format } from "date-fns";
import React from "react";
import { Button, Col, ListGroupItem, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { ReactComponent as OsherLogo } from "../../assets/images/logo-only.svg";
import { es } from "date-fns/locale";
import { useSelector } from "react-redux";

export default function SubclassItem({ subclass }) {
  const profile = useSelector((state) => state.authState.profile);
  return (
    <ListGroupItem style={{ border: "none", margin: "10px 0px" }}>
      <Row className="align-items-center">
        <Col lg>
          <img
            alt={subclass.title}
            style={{ borderRadius: "10px" }}
            className="shadow"
            src={subclass.imageUrl}
            height="150px"
            width="150px"
          ></img>
        </Col>
        <Col lg className="mt-3 text-center">
          <h4>{subclass.title}</h4>
          <p>
            {format(new Date(subclass.date), "EEEE d MMMM HH:mm", {
              locale: es,
            })}
          </p>
        </Col>
        {profile.membershipStatus ? (
          new Date(profile.membershipExpiredDate) <= new Date() ? (
            <Col className="text-right">
              <p>Su membresía ha expirado.</p>
              <Link to="/membersite/payment-info">
                <Button className="mainButton">Renovar Membresía</Button>
              </Link>
            </Col>
          ) : (
            <Col lg={4} className="text-right mt-3">
              <Link
                to={{
                  pathname: `/membersite/subclass/${subclass._id}`,
                  state: {
                    subclass: subclass,
                  },
                }}
              >
                <Button className="mainButton">Ver tema</Button>
              </Link>
            </Col>
          )
        ) : (
          <Col className="text-right">
            <p>Para participar de esta clase, debes ser Miembro OSHER</p>
            <Link to="/membersite/payment-info">
              <Button
                className="btn-light"
                style={{
                  border: "1.5px solid rgb(239, 186, 129)",
                }}
              >
                Ser miembro
                <OsherLogo className="ml-2" style={{ height: "30px" }} />
              </Button>
            </Link>
          </Col>
        )}
      </Row>
    </ListGroupItem>
  );
}
