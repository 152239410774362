import { createAction } from "redux-actions";
import API from "../utils/api";
import { uiActions } from "./uiActions";

export const userActions = {
  GET_USER: createAction("GET_USER"),
  GET_USER_FINISHED: createAction("GET_USER_FINISHED", (user) => {
    return {
      user,
    };
  }),
  GET_USER_FAILED: createAction("GET_USER_FAILED", (error) => {
    return {
      error,
    };
  }),
  UPDATE_USER: createAction("UPDATE_USER"),
  UPDATE_USER_FINISHED: createAction("UPDATE_USER_FINISHED", (user) => {
    return {
      user,
    };
  }),
  UPDATE_USER_FAILED: createAction("UPDATE_USER_FAILED", (error) => {
    return {
      error,
    };
  }),
};

export const getUserAction = (uid) => async (
  dispatch,
  getState,
  getFirebase
) => {
  dispatch(userActions.GET_USER());
  const firebase = getFirebase();
  const status = await firebase.auth();
  if (status.currentUser) {
    const token = await firebase.auth().currentUser.getIdToken();
    API.get(`/users/${uid}`, { headers: { Authorization: `Bearer ${token}` } })
      .then((res) => {
        dispatch(userActions.GET_USER_FINISHED(res.data));
      })
      .catch((error) => {
        dispatch(userActions.GET_USER_FAILED(error));
      });
  }
  dispatch(userActions.GET_USER_FINISHED());
};

export const updateUserAction = (userData, uid) => async (
  dispatch,
  getState,
  getFirebase
) => {
  dispatch(userActions.UPDATE_USER());
  const firebase = getFirebase();
  const token = await firebase.auth().currentUser.getIdToken();
  API.put(`/users/${uid}`, userData, {
    headers: { Authorization: `Bearer ${token}` },
  })
    .then((res) => {
      dispatch(userActions.UPDATE_USER_FINISHED(res.data));
      dispatch(uiActions.SNACKBAR_SUCCESS("Perfil actualizado"));
    })
    .catch((error) => {
      dispatch(userActions.UPDATE_USER_FAILED(error));
    });
};
