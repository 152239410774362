import { createAction } from "redux-actions";
import API from "../utils/api";

export const retreatActions = {
  GET_RETREATS: createAction("GET_RETREATS"),
  GET_RETREATS_FINISHED: createAction("GET_RETREATS_FINISHED", (retreats) => {
    return {
      retreats,
    };
  }),
  GET_RETREATS_ERROR: createAction("GET_RETREATS_ERROR", (error) => {
    return {
      error,
    };
  }),
};

export const getRetreatsAction =
  () => async (dispatch, getState, getFirebase) => {
    dispatch(retreatActions.GET_RETREATS());
    API.get("/retreats")
      .then((res) => {
        dispatch(retreatActions.GET_RETREATS_FINISHED(res.data));
      })
      .catch((error) => {
        dispatch(retreatActions.GET_RETREATS_ERROR(error));
      });
  };
