import { createAction } from "redux-actions";
import API from "../utils/api";

export const classActions = {
  GET_CLASSES: createAction("GET_CLASSES"),
  GET_CLASSES_FINISHED: createAction("GET_CLASSES_FINISHED", (classes) => {
    return {
      classes,
    };
  }),
  GET_CLASSES_ERROR: createAction("GET_CLASSES_ERROR", (error) => {
    return {
      error,
    };
  }),
};

export const getClassesAction = () => (dispatch) => {
  dispatch(classActions.GET_CLASSES());
  API.get("/classes")
    .then((res) => {
      dispatch(classActions.GET_CLASSES_FINISHED(res.data));
    })
    .catch((error) => {
      dispatch(classActions.GET_CLASSES_ERROR(error));
    });
};
