import React from "react";
import { Row } from "react-bootstrap";
import RetreatItem from "./retreat";
const RetreatList = ({ retreats }) => {
  return (
    <Row>
      {retreats &&
        retreats.map((retreat) => {
          return <RetreatItem key={retreat._id} retreat={retreat} />;
        })}
    </Row>
  );
};

export default RetreatList;
