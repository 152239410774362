import React from "react";
import { Row } from "react-bootstrap";
import CourseItem from "./course";
const CourseList = ({ courses }) => {
  return (
    <Row>
      {courses &&
        courses.map((course) => {
          return <CourseItem key={course._id} course={course} />;
        })}
    </Row>
  );
};

export default CourseList;
