import React, { useEffect } from "react";
import { Container } from "react-bootstrap";
import FirstSection from "./firstSection";
import Phrase from "./phrase";
/* import Details from "./details"; */
import LalaFooter from "../footer/footer";

const Landing = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  });
  return (
    <Container fluid>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <FirstSection />
      <br></br>
      <br></br>
      <Phrase />
      <br />
      <br />
      {/* <Details />
      <br></br> */}
      <LalaFooter />
    </Container>
  );
};

export default Landing;
