import React from "react";
import { Card, Button, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";

const CourseItem = ({ course }) => {
  return (
    <Col md={4} className="pt-3">
      <Card
        className="text-center border-0 text-light shadow h-100"
        style={{
          backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.10), rgba(117, 19, 93, 0.25)), url(${course.backgroundImageUrl})`,
          backgroundSize: "cover",
          backgroundPosition: "center center",
        }}
      >
        <Card.Body className="courseCard">
          <Card.Title>{course.title}</Card.Title>
          <Row className="h-100 mx-2">
            <Col className="my-auto">
              <Card.Text>{course.shortDescription}</Card.Text>
            </Col>
          </Row>
        </Card.Body>
        <Card.Footer className="border-0">
          <Link to={`/course/${course._id}`}>
            <Button className="mainButton">Ver Taller</Button>
          </Link>
        </Card.Footer>
      </Card>
    </Col>
  );
};

export default CourseItem;
