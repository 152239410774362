import React, { useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import CourseList from "../courses/courseList";
import { getCoursesAction } from "../../actions/courseActions";
import { getClassesAction } from "../../actions/classActions";
import ClassList from "../classes/classList";
import LoadingPage from "../loadingpage";

const Agenda = () => {
  const dispatch = useDispatch();
  const courses = useSelector((state) => state.courseState.courses);
  const isFetchingCourses = useSelector((state) => state.courseState.fetching);
  const isFetchingClasses = useSelector((state) => state.classState.fetching);
  const classes = useSelector((state) => state.classState.classes);
  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(getCoursesAction());
    dispatch(getClassesAction());
  }, [dispatch]);

  return (
    <Container className="mt-5 pt-5">
      <Row>
        <Col>
          <h3>Talleres</h3>
          {!isFetchingCourses ? (
            <CourseList courses={courses} />
          ) : (
            <LoadingPage />
          )}

          <br />
        </Col>
      </Row>
      <Row>
        <Col>
          <h3>Clases</h3>
          {!isFetchingClasses ? (
            <ClassList classes={classes} />
          ) : (
            <LoadingPage />
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default Agenda;
