import React, { useEffect } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import ClassList from "./classList";
import { Redirect, Link } from "react-router-dom";
import { getClassesAction } from "../../actions/classActions";
import { getUserAction } from "../../actions/userActions";
import LoadingPage from "../loadingpage";
import { ReactComponent as OsherLogo } from "../../assets/images/logo-only.svg";
import UpdateUserForm from "../userpage/userUpdateForm";
import ClassItem from "./classItem";

const Practicas = () => {
  const auth = useSelector((state) => state.firebaseState.auth);
  const classes = useSelector((state) => state.classState.classes);
  const profile = useSelector((state) => state.authState.profile);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getClassesAction());
    if (auth.uid) if (!profile) dispatch(getUserAction(auth.uid));
  }, [dispatch, profile, auth]);

  if (!auth.uid) return <Redirect to="/membersite/login"></Redirect>;
  if (!profile) return <LoadingPage />;
  if (profile && !profile.name)
    return (
      <Row className="pt-5">
        <Col>
          <h2>Bienvenid@ a tu cuenta, por favor ingresá tus datos.</h2>
          <br></br>
          <UpdateUserForm />
        </Col>
      </Row>
    );
  return (
    <Container className="mt-4 pt-4">
      {!profile.membershipStatus && (
        <Row>
          <Col>
            <h5>
              ¿Querés ser miembro OSHER?{" "}
              <Link to="/membersite/payment-info">
                <Button
                  className="btn-light ml-4"
                  style={{
                    border: "1.5px solid rgb(239, 186, 129)",
                  }}
                >
                  Ser miembro
                  <OsherLogo className="ml-2" style={{ height: "30px" }} />
                </Button>
              </Link>
            </h5>
          </Col>
        </Row>
      )}
      <br />
      <br />
      <br />
      <Row>
        <Col>
          <h1>Prácticas de la Membresía</h1>
        </Col>
      </Row>
      <Row>
        {classes &&
          classes
            .filter((element) => element.isTrainee)
            .map((element) => {
              return (
                <Col md={4} className="mt-4" key={element._id}>
                  <ClassItem lalaClass={element} />
                </Col>
              );
            })}
      </Row>
      <br />
      <br />
    </Container>
  );
};

export default Practicas;
