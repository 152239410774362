import { format } from "date-fns";
import { es } from "date-fns/locale";
import React from "react";
import { Card, Button, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";

const RetreatItem = ({ retreat }) => {
  return (
    <Col md={4} className="pt-3">
      <Card
        className="text-center border-0 text-light shadow h-100"
        style={{
          backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.10), rgba(117, 19, 93, 0.25)), url(${retreat.imageUrl})`,
          backgroundSize: "cover",
          backgroundPosition: "center center",
        }}
      >
        <Card.Body className="courseCard">
          <Card.Title>{retreat.title}</Card.Title>
          <Row className="h-100 mx-2">
            <Col className="my-auto">
              <Card.Text>
                {format(new Date(retreat.date), "d MMMM", {
                  locale: es,
                })}{" "}
                -{" "}
                {format(new Date(retreat.endDate), "d MMMM", {
                  locale: es,
                })}
              </Card.Text>
            </Col>
          </Row>
        </Card.Body>
        <Card.Footer className="border-0">
          <Link to={`/retreats/${retreat._id}`}>
            <Button className="mainButton">Ver Retiro</Button>
          </Link>
        </Card.Footer>
      </Card>
    </Col>
  );
};

export default RetreatItem;
