import React from "react";
import { Row, Col, Container } from "react-bootstrap";

//Images import
/* import tedelalmaImage from "../../assets/images/tedelalma.png"; */
import osherImage from "../../assets/images/osherImage.png";
const TeDelAlma = () => {
  return (
    <Row>
      <Col md>
        <Container>
          <br></br>
          <br></br>
          <h3 className="secondaryFont mainColorText display-4">
            Sistema OSHER
          </h3>
          <br></br>
          <p>
            Todos tenemos heridas y situaciones que afectan nuestra vida y nos
            impiden ser plenamente felices, descubrirlas y sanarlas es posible.
            Te invito a que juntos emprendamos este camino. Te propongo que
            aprendas a conocerte y así puedas vivir desde tu esencia, limpiar
            programas mentales para que poco a poco encuentres tu coherencia.
          </p>
          <p>
            Situaciones de miedo, enojos y preocupaciones son el reflejo de tu
            estado interno, este sistema te guiará a sacar todas esas capas que
            no dejan ver tu esencia de paz y armonía.
          </p>
          <p>
            Quiero inspirarte para que cambies tu perspectiva, que realmente
            sientas que tu felicidad está al alcance de tu mano. Mi propuesta es
            darte herramientas para conocerte y conectar con tu corazón dejando
            atrás el esfuerzo.
          </p>
          <br />
        </Container>
      </Col>
      <Col
        md
        style={{
          backgroundImage: `url(${osherImage})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
      ></Col>
    </Row>
  );
};

export default TeDelAlma;
