import { handleActions } from "redux-actions";

export const socketReducer = handleActions({
    CONNECTED: (state, action) => {
        return {
            ...state,
            connected: true
        }
    }
}, {
    connected: false
})