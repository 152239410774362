import React, { useEffect } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import { useSelector } from "react-redux";
// import api from "../../utils/api";
import { Redirect } from "react-router-dom";

const PaymentInfo = () => {
  const profile = useSelector((state) => state.authState.profile);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  /* const startPayment = () => {
    var body = {
      payer: {
        name: profile.name,
        surname: profile.lastName,
        email: profile.email,
      },
    };
    api
      .post("/process-payment", body)
      .then((res) => {
        if (res.data.sandbox_init_point)
          window.location.href = res.data.init_point;
      })
      .catch((error) => console.log(error));
  }; */
  if (!profile) return <Redirect to="/membersite/login" />;
  return (
    <Container className="mt-5 pt-5">
      <Row>
        <Col className="text-center">
          <h1 className="mainColorText">Forma de pago y descuentos</h1>
          <br />
          <p>Contactanos si tenés alguna consulta.</p>
          <p>+54 9 261 653 4815</p>
          <p>
            Siendo miembro OSHER tendrás descuentos en talleres, en retiros, en
            tienda online y en estadías en Robles de Besares.
          </p>
        </Col>
      </Row>
      <Row className="mt-5">
        <Col className="mb-4">
          <h4 className="mainColorText">A través de Mercado Pago</h4>
          <h5>$ 15.500 pesos argentinos</h5>
          <p>El valor de la membresia en pesos podra sufrir aumentos cada 4 meses. El valor en dolares se mantendra sin cambios</p>
          <ul>
            <li className="mainColorText">Para pagos desde Argentina.</li>
            <li>Tarjeta de crédito o débito.</li>
            <li>Se puede pagar 10 meses, y disfrutar 12 meses de membresía</li>
            <li>
              Al ser una suscripción mensual, se debitará mensualmente el mismo
              valor.
            </li>
            <li>Podes darte de baja cuando quieras.</li>
          </ul>

          <Button
            block
            className="mainButton pt-2 pb-2"
            onClick={() => window.open("https://www.mercadopago.com/mla/debits/new?preapproval_plan_id=2c93808477565c7401775874631a0039")}
          >
            Subscribirse con MercadoPago
          </Button>
        </Col>
        <Col>
          <h4 className="mainColorText">A través de PayPal</h4>
          <h5>USD 29 dolares americanos</h5>
          <ul>
            <li className="mainColorText">Para pagos internacionales.</li>
            <li>El pago se realiza a través de PayPal.</li>
            <li>VISA, MasterCard o Saldo PayPal.</li>
            <li>
              Al ser una subscripción mensual, se debitará mensualmente el mismo
              valor. Podes darte de baja cuando quieras.
            </li>
          </ul>
          <Row>
            <Col className="text-center">
              <form
                action="https://www.paypal.com/cgi-bin/webscr"
                method="post"
                target="_top"
              >
                <input type="hidden" name="cmd" value="_s-xclick" />
                <input
                  type="hidden"
                  name="hosted_button_id"
                  value="EEV2MTZU5PKA6"
                />
                <table>
                  <tr>
                    <td>
                      <input type="hidden" name="on0" value="Correo" />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <input
                        type="hidden"
                        name="os0"
                        maxlength="200"
                        value={profile._id}
                      />
                    </td>
                  </tr>
                </table>
                <input
                  type="image"
                  src="https://www.paypalobjects.com/es_XC/AR/i/btn/btn_subscribeCC_LG.gif"
                  border="0"
                  name="submit"
                  alt="PayPal - The safer, easier way to pay online!"
                />
                <img
                  alt=""
                  border="0"
                  src="https://www.paypalobjects.com/es_XC/i/scr/pixel.gif"
                  width="1"
                  height="1"
                />
              </form>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default PaymentInfo;
