import React from "react";
import { Row, Col } from "react-bootstrap";
import LibraryClassItem from "./libraryClassItem";

const LibraryClassList = ({ classes }) => {
  return (
    <Row>
      {classes &&
        classes.map((element) => {
          return (
            <Col md={4} className="mt-4" key={element._id}>
              <LibraryClassItem lalaClass={element} />
            </Col>
          );
        })}
    </Row>
  );
};

export default LibraryClassList;
