import React from "react";
import {
  Container,
  Row,
  Col,
  Carousel,
  Button,
  NavLink,
} from "react-bootstrap";
import carousel1 from "../../assets/images/carousel1.png";
import carousel2 from "../../assets/images/carousel2.jpg";
import carousel3 from "../../assets/images/carousel3.png";
import carousel4 from "../../assets/images/carousel4.jpg";
import carousel5 from "../../assets/images/carousel5.png";

const Robles = () => {
  return (
    <Container>
      <Row>
        <Col className="text-center">
          <br />
          <br />
          <h3 className="secondaryFont mainColorText display-4">
            Mi cielo en la tierra
          </h3>
        </Col>
      </Row>
      <br />
      <Row style={{ backgroundColor: "rgb(246, 246, 246)" }}>
        <Col>
          <Carousel>
            <Carousel.Item>
              <div
                style={{
                  backgroundImage: `url(${carousel2})`,
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                  height: "500px",
                  backgroundPosition: "bottom center",
                }}
              ></div>
            </Carousel.Item>
            <Carousel.Item>
              <div
                style={{
                  backgroundImage: `url(${carousel1})`,
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                  height: "500px",
                }}
              ></div>
            </Carousel.Item>

            <Carousel.Item>
              <div
                style={{
                  backgroundImage: `url(${carousel3})`,
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                  height: "500px",
                }}
              ></div>
            </Carousel.Item>
            <Carousel.Item>
              <div
                style={{
                  backgroundImage: `url(${carousel4})`,
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                  height: "500px",
                }}
              ></div>
            </Carousel.Item>
            <Carousel.Item>
              <div
                style={{
                  backgroundImage: `url(${carousel5})`,
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                  height: "500px",
                }}
              ></div>
            </Carousel.Item>
          </Carousel>
        </Col>
      </Row>
      <Row>
        <Col className="text-center">
          <br />
          <br />
          <NavLink
            rel="noopener"
            href="https://roblesdebesares.com.ar"
            target="_blank"
          >
            <Button className="mainButton shadow">CONOCER MÁS</Button>
          </NavLink>
        </Col>
      </Row>
      <br id="camino"></br>
    </Container>
  );
};

export default Robles;
