import React, { Component } from "react";
import { connect } from "react-redux";
import CourseList from "./courseList";
import { Container, Row, Col, Alert, Button } from "react-bootstrap";
import { bindActionCreators } from "redux";
import { getCoursesAction } from "../../actions/courseActions";
import {
  getCourses,
  getCoursesError,
  getCoursesFetching,
} from "../../reducers/coursesReducer";
import LoadingPage from "../loadingpage";
import { Link } from "react-router-dom";

class Courses extends Component {
  constructor(props) {
    super(props);
    this.shouldComponentRender = this.shouldComponentRender.bind(this);
  }

  componentWillMount() {
    const { getCourses } = this.props;
    getCourses();
  }

  shouldComponentRender() {
    const { fetching } = this.props;
    if (fetching === false) return false;
    return true;
  }

  render() {
    const { courses, error } = this.props;

    if (this.shouldComponentRender()) return <LoadingPage />;
    return (
      <Container>
        <Row>
          <Col>
            <h3 className="text-center secondaryFont mainColorText display-4">
              Talleres de Autoconocimiento
            </h3>
          </Col>
        </Row>
        <br />
        <br />
        {error && <Alert variant="danger">{error}</Alert>}
        <CourseList courses={courses} />
        <br></br>
        <br></br>
        {/* <Col className="text-center">
          <Link to="/agenda">
            <Button className="mainButton" size="lg">
              Ver más
            </Button>
          </Link>
        </Col> */}
      </Container>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    error: getCoursesError(state),
    courses: getCourses(state),
    fetching: getCoursesFetching(state),
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getCourses: getCoursesAction,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Courses);
