import React from "react";
import { Row, Col, Container, Button } from "react-bootstrap";
import autoconocimientoImage from "../../assets/images/autoconocimiento.jpg";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

const Retiros = () => {
  return (
    <Row>
      <Col
        md
        style={{
          backgroundImage: `url(${autoconocimientoImage})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
      ></Col>
      <Col>
        <Container>
          <br></br>
          <br></br>
          <h3 className="secondaryFont mainColorText display-4">Retiros</h3>
          <br />
          <p>
            Abro mi corazón y las puertas de mi casa con la intención de que
            sanemos juntos.
          </p>
          <p>
            Trabajar en grupo es la forma de ver reflejado en otros lo que que
            no has podido ver en soledad. Todos somos unidad y compartimos el
            propósito de sanar. Estos son encuentros de almas y de gran
            profundidad.
          </p>
          <p>
            Tras años de formación y experiencia, desarrollé un sistema que te
            permitirá recuperar el equilibrio de tu cuerpo, tus emociones y tu
            mente, así encontrarte y lograr que te reconozcas y te abraces.
          </p>
          <p>
            Alejarte del bullicio y darte tiempo para ti mismo, es una forma de
            alcanzar estados de paz y armonía.
          </p>
          <br />

          <Link to="/retreats">
            <Button className="mainButton" size="lg">
              Ver más
            </Button>
          </Link>
        </Container>
      </Col>
    </Row>
  );
};

export default Retiros;
