import React from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import { useLocation, Redirect } from "react-router-dom";
const StoreItemDetail = () => {
  const { state } = useLocation();

  if (!state) return <Redirect to="/store" />;
  return (
    <Container className="pt-5 mt-5">
      <Row className="mt-5">
        <Col sm={12} md={6} className="mb-5">
          <div
            style={{
              height: "60vh",
              backgroundImage: `url(${state.image})`,
              backgroundSize: "cover",
              backgroundPosition: "center center",
            }}
          ></div>
        </Col>
        <Col sm={12} md={6} className="align-self-center">
          <h5>{state.title}</h5>
          <h6>{state.description}</h6>
          <br />
          <br />
          <p>{state.secondaryDescription}</p>
          <br />
          <a
            href={`https://api.whatsapp.com/send?phone=+5492616534815&text=%20Hola, estoy interesado en comprar ${state.title}`}
          >
            <Button className="mainButton">Comprar</Button>
          </a>
        </Col>
      </Row>
    </Container>
  );
};

export default StoreItemDetail;
