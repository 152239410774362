import React, { useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { updateUserAction } from "../../actions/userActions";
import { Form, Button } from "react-bootstrap";

const UserUpdateForm = (props) => {
  const { updateUser, auth, profile } = props;
  const [name, setName] = useState(profile.name);
  const [lastName, setLastName] = useState(profile.lastName);
  const [newsletter, setNewsletter] = useState(profile.newsletter);

  const handleSubmit = (e) => {
    e.preventDefault();
    updateUser(
      { name: name, lastName: lastName, newsletter: newsletter },
      auth.uid
    );
  };

  return (
    <div>
      <Form onSubmit={handleSubmit}>
        <Form.Group>
          <Form.Label>Nombre</Form.Label>
          <Form.Control
            id="name"
            placeholder="Ingrese su nombre"
            required
            onChange={(e) => setName(e.target.value)}
            value={name}
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>Apellido</Form.Label>
          <Form.Control
            id="lastName"
            placeholder="Ingrese su apellido"
            required
            onChange={(e) => setLastName(e.target.value)}
            value={lastName}
          />
        </Form.Group>
        <Form.Group>
          <Form.Check
            type="switch"
            id="newsletter"
            label="Newsletter"
            onChange={() => setNewsletter(!newsletter)}
            checked={newsletter}
          />
        </Form.Group>
        <Form.Group>
          <Button type="submit" className="mainButton">
            Confirmar
          </Button>
        </Form.Group>
      </Form>
    </div>
  );
};

const mapStateToProps = (state) => {
  return { profile: state.authState.profile, auth: state.firebaseState.auth };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      updateUser: (userDetails, uid) => updateUserAction(userDetails, uid),
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(UserUpdateForm);
