import React from "react";
import { Card, Row, Col, Button } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";

const LibraryClassItem = (props) => {
  const { lalaClass } = props;
  const { year } = useParams();
  return (
    <Card
      className="text-center border-0 text-light shadow h-100"
      style={{
        backgroundImage: `linear-gradient(rgba(0,0,0,0.54), rgba(0,0,0,0.5)), url(${lalaClass.backgroundImageUrl})`,
        backgroundSize: "cover",
        backgroundPosition: "center center",
      }}
    >
      <Card.Body className="courseCard">
        <Card.Title>{lalaClass.title}</Card.Title>
        <Row className="h-100">
          <Col className="my-auto">
            <Card.Text>{lalaClass.shortDescription}</Card.Text>
          </Col>
        </Row>
      </Card.Body>
      <Card.Footer className="border-0">
        <Link
          to={{
            pathname: `/membersite/library/${year}/class/${lalaClass._id}`,
            lalaClass: lalaClass,
          }}
        >
          <Button className="mainButton">Ver Más</Button>
        </Link>
      </Card.Footer>
    </Card>
  );
};

export default LibraryClassItem;
