import { handleActions } from "redux-actions";

export const retreatsReducer = handleActions(
  {
    UPDATED_RETREAT: (state, action) => {
      return {
        ...state,
        retreats: [
          ...state.retreats.filter(
            (element) => element._id !== action.payload._id
          ),
          action.payload,
        ],
      };
    },
    GET_RETREATS: (state, action) => {
      return {
        ...state,
        fetching: true,
      };
    },
    GET_RETREATS_FINISHED: (state, action) => {
      return {
        ...state,
        fetching: false,
        retreats: action.payload.retreats,
      };
    },
    GET_RETREATS_ERROR: (state, action) => {
      return {
        ...state,
        fetching: false,
        error: action.error,
      };
    },
  },
  {
    fetching: false,
    retreats: [],
    error: null,
  }
);

export const getRetreats = (state) => state.retreatsState.retreats;
export const getRetreatsFetching = (state) => state.retreatsState.fetching;
export const getRetreatsError = (state) => state.retreatsState.error;
