import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import { reactReduxFirebase, getFirebase } from "react-redux-firebase";
import rootReducer from "../reducers";
import fbConfig from "../config/fbConfig";

const configureStore = (preloadedState) =>
  createStore(
    rootReducer,
    preloadedState,
    compose(
      applyMiddleware(thunk.withExtraArgument(getFirebase)),
      reactReduxFirebase(fbConfig, { attatchAuthIsReady: true })
    )
  );
export default configureStore;
