import React, { useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import LoadingPage from "../loadingpage";
import { getUserAction } from "../../actions/userActions";
import { bindActionCreators } from "redux";
import UpdateUserForm from "./userUpdateForm";
import Classes from "../classes";

const Userpage = (props) => {
  const { auth, profile, getUser } = props;
  useEffect(() => {
    if (auth.uid) {
      if (!profile) {
        getUser(auth.uid);
      }
    }
  });

  if (!auth.uid) return <Redirect to="/membersite/login" />;

  return (
    <Container style={{ paddingTop: "66px" }}>
      {!profile ? (
        <LoadingPage />
      ) : !profile.name ? (
        <Row className="pt-5">
          <Col>
            <h2>Bienvenid@ a tu cuenta, por favor ingresá tus datos.</h2>
            <br></br>
            <UpdateUserForm />
          </Col>
        </Row>
      ) : (
        <Classes />
      )}
    </Container>
  );
};

const mapStateToProps = (state) => {
  return {
    profile: state.authState.profile,
    auth: state.firebaseState.auth,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getUser: (uid) => getUserAction(uid),
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Userpage);
