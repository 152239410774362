import React from "react";
import { Row, Col, Button } from "react-bootstrap";
import headerImage from "../../assets/images/header.jpg";
import { Link } from "react-router-dom";
import { ReactComponent as OsherLogo } from "../../assets/images/logo-full.svg";

var headerStyle = {
  backgroundImage: `linear-gradient(to right, #21252985 , #ffff0000),url(${headerImage})`,
  backgroundPosition: "center",
};

const Header = ({ text, buttonText }) => {
  return (
    <Row className="mainHeader" style={headerStyle}>
      <Col md={{ span: 5, offset: 1 }} className="mt-auto">
        <OsherLogo style={{ height: "70px", marginBottom: "40px" }} />
        <h2 className="text-white">{text}</h2>
        <br></br>
        <Link to="/membersite">
          <Button className="mainButton">{buttonText}</Button>
        </Link>
        <br></br>
        <br></br>
        <br id="agenda"></br>
        <br></br>
      </Col>
    </Row>
  );
};

export default Header;
