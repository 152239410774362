import React, { useEffect } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, useParams } from "react-router";
import { Link } from "react-router-dom";
import { getClassesAction } from "../../actions/classActions";
import { getUserAction } from "../../actions/userActions";
import { ReactComponent as OsherLogo } from "../../assets/images/logo-only.svg";
import LoadingPage from "../loadingpage";
import LibraryClassList from "./libraryClassList";

export default function Library() {
  const auth = useSelector((state) => state.firebaseState.auth);
  const classes = useSelector((state) => state.classState.classes);
  const profile = useSelector((state) => state.authState.profile);
  const { year } = useParams();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getClassesAction());
    if (auth.uid) if (!profile) dispatch(getUserAction(auth.uid));
  }, [dispatch, profile, auth]);

  if (!auth.uid) return <Redirect to="/membersite/login"></Redirect>;
  if (!profile) return <LoadingPage />;

  return (
    <Container className="mt-5 pt-5">
      {!profile.membershipStatus && (
        <Row>
          <Col>
            <h5>
              ¿Querés ser miembro OSHER?{" "}
              <Link to="/membersite/payment-info">
                <Button
                  className="btn-light ml-4"
                  style={{
                    border: "1.5px solid rgb(239, 186, 129)",
                  }}
                >
                  Ser miembro
                  <OsherLogo className="ml-2" style={{ height: "30px" }} />
                </Button>
              </Link>
            </h5>
          </Col>
        </Row>
      )}
      <Row>
        <Col>
          <h1>Contenido {year} de la Membresía</h1>
        </Col>
      </Row>
      <LibraryClassList
        classes={classes.filter((e) => !e.isTrainee && 
          e.subclasses.some((subclass) => {
            if (
              new Date(subclass.date).getFullYear().toString() ===
              year.toString()
            ) {
              return true;
            }
            return false;
          })
        )}
      />
      <br />
      <br />
    </Container>
  );
}
