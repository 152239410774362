import React from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Form,
  Button,
  Alert,
} from "react-bootstrap";
import { Redirect } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useState } from "react";
import LoadingPage from "../loadingpage";
import { getResetPasswordAction } from "../../actions/authActions";

const ResetPassword = () => {
  const auth = useSelector((state) => state.firebaseState.auth);
  const fetching = useSelector((state) => state.authState.fetching);
  const authError = useSelector((state) => state.authState.authError);
  const [email, setEmail] = useState("");
  const dispatch = useDispatch();
  if (auth.uid) return <Redirect to="/membersite/user" />;

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(getResetPasswordAction(email));
  };
  return (
    <Container fluid style={{ backgroundColor: "rgb(239, 186, 129)" }}>
      <Row style={{ minHeight: "100vh" }}>
        <Col md={{ span: 8, offset: 2 }} className="my-auto">
          <Card className="shadow border-0">
            <Card.Header className="bg-white border-bottom-0">
              <h2>Restablecer Contraseña</h2>
            </Card.Header>
            <Card.Body>
              <p>
                Ingrese su correo y envie la peticion para reiniciar su
                contraseña, recibirá un correo con los pasos a seguir para
                restablecer la misma.
              </p>
              <Form onSubmit={handleSubmit}>
                <Form.Group>
                  <Form.Label className="mainColorText">Correo</Form.Label>
                  <Form.Control
                    id="email"
                    onChange={(e) => setEmail(e.target.value)}
                    type="email"
                    placeholder="Ingrese su correo"
                  ></Form.Control>
                </Form.Group>
                <Form.Group>
                  {fetching ? (
                    <LoadingPage />
                  ) : (
                    <Button type="submit" className="mainButton">
                      Enviar!
                    </Button>
                  )}
                </Form.Group>
                {authError ? (
                  <Alert variant="danger">{authError["message"]}</Alert>
                ) : null}
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default ResetPassword;
