import React from "react";
import { Row, Col, Carousel } from "react-bootstrap";

const Testimonies = () => {
  return (
    <Row style={{ backgroundColor: "rgb(246, 246, 246)" }}>
      <Col>
        <Carousel interval={20000}>
          <Carousel.Item>
            <br></br>
            <div
              className="mt-5 mb-5 text-center mx-auto"
              style={{ maxWidth: "70%" }}
            >
              <i>
                <h5>
                  <b>"</b> Conocer a Lala fue un antes y un después en mi vida.
                  Siento que lo que soy, en gran parte, es consecuencia de las
                  buenas decisiones gracias a sus conceptos. Siempre está
                  conmigo, la llevo en mi corazón. Es un ser especial que me
                  ayudó a despertar, crear y disfrutar de la vida mágica que hoy
                  tengo. Gracias, gracias, gracias. <b>"</b>
                </h5>
              </i>
              <br></br>
              <p>Fernu Perez Cuesta</p>
            </div>
          </Carousel.Item>
          <Carousel.Item>
            <div
              className="mt-5 mb-5 text-center mx-auto"
              style={{ maxWidth: "70%" }}
            >
              <i>
                <h5>
                  <b>"</b> Conocí a Lala en Colombia, donde compartió su luz,
                  amor y sabiduría. Su mirada bondadosa, voz apacible y su
                  actitud de aceptación me cautivaron. Me enseñó a conocerme y a
                  despertar para alcanzar día a día mi mejor versión. Aprendí a
                  ponerme al servicio de la luz. Infinitas gracias, por ser y
                  estar aquí y ahora, por bajar el cielo a la tierra. <b>"</b>
                </h5>
              </i>
              <br></br>
              <p>Karina Atoche</p>
            </div>
          </Carousel.Item>
          <Carousel.Item>
            <div
              className="mt-5 mb-5 text-center mx-auto"
              style={{ maxWidth: "70%" }}
            >
              <i>
                <h5>
                  <b>"</b> Lala me ayudó a soltar mis angustias, dudas y
                  pesares, me mostró poco a poco otra perspectiva de mi realidad
                  que me permitió descomprimir lo que estaba viviendo. Gracias a
                  sus herramientas pude entender que todo se TRATA DE MI. No fue
                  fácil, pero si posible. Hoy sigo disfrutando de cada una de
                  sus charlas y de sus exquisitos “Te del alma” que nutren mi
                  espíritu y me ayudan a sostener lo aprendido. <b>"</b>
                </h5>
              </i>
              <br></br>
              <p>Gladis Bietman</p>
            </div>
          </Carousel.Item>
          <Carousel.Item>
            <div
              className="mt-5 mb-5 text-center mx-auto"
              style={{ maxWidth: "70%" }}
            >
              <i>
                <h5>
                  <b>"</b> Nunca me pregunté a qué había venido a este mundo,
                  hasta que lo perdí todo y en ese momento la vida me puso a
                  Lala en mi camino. No fue fácil, con ternura y simpleza, sus
                  palabras se encendían como lucecitas dentro mío. Aplicaba todo
                  lo que ella me sugería, logré con Reiki el poder de mis manos
                  y con su acompañamiento renací a la vida. Comencé a entender
                  las leyes universales, el amor y que TODO SE TRATA DE MI.
                  Ahora me siento plena, en paz y con muchas ganas de vivir mi
                  nueva versión. <b>"</b>
                </h5>
              </i>
              <br></br>
              <p>Mirna Montero</p>
            </div>
          </Carousel.Item>
          <Carousel.Item>
            <div
              className="mt-5 mb-5 text-center mx-auto"
              style={{ maxWidth: "70%" }}
            >
              <i>
                <h5>
                  <b>"</b> Cuando conocí a Lala sentí despertar. Aprendí que
                  menos es más y que todo lo que sucede es perfecto para mi
                  propia evolución. La ayudé a organizar los “Te del Alma” en
                  San juan y así todos los meses pude recordar y sostener.
                  Aprendí a vivir respetando los ritmos y procesos de cada uno,
                  que nadie me hace nada, que todo siempre se trata de mi y que
                  agradecer me lleva a un mejor lugar cada día. Ella me guía, me
                  da fuerza y me repite todo el tiempo la importancia de poner
                  en práctica lo aprendido. <b>"</b>
                </h5>
              </i>
              <br></br>
              <p>Carolina Gioja</p>
            </div>
          </Carousel.Item>
          <Carousel.Item>
            <div
              className="mt-5 mb-5 text-center mx-auto"
              style={{ maxWidth: "70%" }}
            >
              <i>
                <h5>
                  <b>"</b> Lala me hablaba de cosas que yo no entendía pero
                  resonaban en mí. Siempre me sentí una escéptica, aunque no
                  podía negarlo el cambio era evidente, mi mundo mutaba. Ella
                  logró abrir mi corazón y empecé a escuchar con claridad.
                  Agradezco su amistad que me nutre día a día y me permite
                  descubrirme creadora y plena. Te quiero! <b>"</b>
                </h5>
              </i>
              <br></br>
              <p>Mónica Caamaño</p>
            </div>
          </Carousel.Item>
          <Carousel.Item>
            <div
              className="mt-5 mb-5 text-center mx-auto"
              style={{ maxWidth: "70%" }}
            >
              <i>
                <h5>
                  <b>"</b> Un día fui a meditar para sacarme el estrés del día y
                  con el tiempo terminé encontrándome conmigo mismo. Lala me
                  enseñó que la meditación es una gran herramienta para sanar,
                  para encontrarte con vos y para abrir un canal directo con el
                  universo. Aunque ella diga que no, para mí es una maestra de
                  la vida, uno de esos seres que viene al mundo a mostrarnos la
                  magia de estar vivos. Gracias. Gracias. Gracias. <b>"</b>
                </h5>
              </i>
              <br></br>
              <p>Atilio Aciar</p>
            </div>
          </Carousel.Item>
          <Carousel.Item>
            <div
              className="mt-5 mb-5 text-center mx-auto"
              style={{ maxWidth: "70%" }}
            >
              <i>
                <h5>
                  <b>"</b> Lala me ayudo a entender quién soy, que dones y
                  talentos traigo a este mundo para compartir. Auto conocerme le
                  puso luz a mi camino. Es un gran cambio de perspectiva, cada
                  uno es perfecto a su manera y si nos conocemos podemos
                  expandir lo mejor hacia nuevos horizontes. Con Lala fue mi
                  despertar. <b>"</b>
                </h5>
              </i>
              <br></br>
              <p>Agustina Boullaude</p>
            </div>
          </Carousel.Item>
        </Carousel>
      </Col>
    </Row>
  );
};

export default Testimonies;
