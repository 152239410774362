import React, { useEffect } from "react";
import { Container, Col, Row } from "react-bootstrap";
import UpdateUserForm from "./userUpdateForm";
import { getUserAction } from "../../actions/userActions";
import { Redirect } from "react-router-dom";
import LoadingPage from "../../components/loadingpage";
import { useSelector, useDispatch } from "react-redux";
/* import api from "../../utils/api"; */
import { ReactComponent as OsherLogo } from "../../assets/images/logo-only.svg";

const UserDetails = (props) => {
  const auth = useSelector((state) => state.firebaseState.auth);
  const profile = useSelector((state) => state.authState.profile);
  const dispatch = useDispatch();
  useEffect(() => {
    if (!profile) {
      dispatch(getUserAction(auth.uid));
    }
  }, [dispatch, auth, profile]);

  if (!auth.uid) return <Redirect to="/membersite/login" />;
  return (
    <Container style={{ paddingTop: "66px" }}>
      {!profile ? (
        <LoadingPage />
      ) : (
        <Row>
          <Col>
            <Row className="pt-5">
              <Col>
                <h1>Mi Perfil</h1>
                <br></br>
                <UpdateUserForm />
              </Col>
            </Row>
            <br />
            <Row>
              <Col>
                <OsherLogo style={{ height: "70px" }} />
                <br></br>
                {profile.membershipStatus ? (
                  <p>Miembro OSHER</p>
                ) : (
                  <p>Miembro Standard</p>
                )}
              </Col>
            </Row>
          </Col>
        </Row>
      )}
    </Container>
  );
};

export default UserDetails;
