import React, { Component } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Form,
  Button,
  Alert,
} from "react-bootstrap";
import { Link, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getLoginAction } from "../../actions/authActions";
import LoadingPage from "../loadingpage";

class Login extends Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  state = {
    email: "",
    password: "",
  };

  handleChange = (e) => {
    e.persist();
    this.setState((prevState) => ({
      ...prevState,
      [e.target.id]: e.target.value,
    }));
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.login(this.state);
  };
  render() {
    const { authError, auth, fetching } = this.props;
    if (auth.uid) return <Redirect to="/membersite/user" />;
    return (
      <Container fluid style={{ backgroundColor: "rgb(239, 186, 129)" }}>
        <Row style={{ minHeight: "100vh" }}>
          <Col md={{ span: 8, offset: 2 }} className="my-auto">
            <Card className="shadow border-0">
              <Card.Header className="bg-white border-bottom-0">
                <h2>Iniciar Sesion</h2>
              </Card.Header>
              <Card.Body>
                <Form onSubmit={this.handleSubmit}>
                  <Form.Group>
                    <Form.Label className="mainColorText">Correo</Form.Label>
                    <Form.Control
                      id="email"
                      onChange={this.handleChange}
                      type="email"
                      placeholder="Ingrese su correo"
                    ></Form.Control>
                  </Form.Group>
                  <Form.Group>
                    <Form.Label className="mainColorText">
                      Contraseña
                    </Form.Label>
                    <Form.Control
                      id="password"
                      onChange={this.handleChange}
                      type="password"
                      placeholder="Ingrese su contraseña"
                    ></Form.Control>
                  </Form.Group>
                  {authError ? (
                    <Alert variant="danger">{authError["message"]}</Alert>
                  ) : null}
                  <Form.Group>
                    {fetching ? (
                      <LoadingPage />
                    ) : (
                      <Button type="submit" className="mainButton">
                        Ingresar!
                      </Button>
                    )}
                  </Form.Group>
                  <Form.Group>
                    <p>
                      Todavia no tenes cuenta? Creá una{" "}
                      <Link
                        className="mainColorText text-decoration-none"
                        to="/membersite/register"
                      >
                        aqui
                      </Link>
                    </p>
                  </Form.Group>
                  <Form.Group>
                    <p>
                      Olvidaste tu contraseña?{" "}
                      <Link
                        className="mainColorText text-decoration-none"
                        to="/membersite/reset-password"
                      >
                        Click Aqui
                      </Link>
                    </p>
                  </Form.Group>
                </Form>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.firebaseState.auth,
    authError: state.authState.authError,
    fetching: state.authState.fetching,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      login: (credentials) => getLoginAction(credentials),
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Login);
