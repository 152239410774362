import React, { useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  ListGroup,
  ListGroupItem,
} from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import LoadingPage from "../loadingpage";
import { Link, useParams } from "react-router-dom";
import { getClassesAction } from "../../actions/classActions";
import { getUserAction } from "../../actions/userActions";
import { format } from "date-fns";
import { es } from "date-fns/locale";
import { ReactComponent as OsherLogo } from "../../assets/images/logo-only.svg";

const LibraryClassDetail = (props) => {
  const profile = useSelector((state) => state.authState.profile);
  const auth = useSelector((state) => state.firebaseState.auth);
  const lalaClass = useSelector((state) =>
    state.classState.classes.find(
      (element) => element._id === props.match.params.id
    )
  );
  const { year } = useParams();
  const dispatch = useDispatch();
  useEffect(() => {
    window.scrollTo(0, 0);
    if (!lalaClass) dispatch(getClassesAction());
    if (auth.uid && !profile) dispatch(getUserAction(auth.uid));
  }, [lalaClass, auth, profile, dispatch]);

  if (!lalaClass) return <LoadingPage />;
  return (
    <Container style={{ paddingTop: "66px" }}>
      <Row className="pt-4">
        <Col>
          <h1 className="mainColorText">{lalaClass.title}</h1>
        </Col>
      </Row>
      <br></br>
      <Row>
        <Col md>
          {lalaClass.link ? (
            <iframe
              title={lalaClass.title}
              src={lalaClass.link}
              width="100%"
              height="300px"
              frameBorder="0"
              allow="autoplay; fullscreen"
              allowFullScreen
            ></iframe>
          ) : (
            <div
              className="shadow"
              style={{
                width: "100%",
                height: "300px",
                backgroundImage: `url(${lalaClass.imageUrl})`,
                backgroundSize: "cover",
                borderRadius: "5px",
              }}
            ></div>
          )}
        </Col>
        <Col md>
          <h2>{lalaClass.shortDescription}</h2>
          <p>{lalaClass.description}</p>
        </Col>
      </Row>
      <br></br>
      <Row>
        {!profile ? (
          <Col>
            <h3>Para apoder ver los temas hacé tu cuenta gratis.</h3>
            <Link to="/membersite/register">
              <Button className="mainButton">Hacerse una cuenta</Button>
            </Link>
          </Col>
        ) : (
          <Col>
            <h1>Temas</h1>
            <ListGroup variant="flush">
              {lalaClass.subclasses &&
                lalaClass.subclasses
                  .filter((e) => {
                    if (
                      new Date(e.date).getFullYear().toString() ===
                      year.toString()
                    ) {
                      return true;
                    }
                    return false;
                  })
                  .sort((a, b) => {
                    if (a.position || b.position) {
                      if (a.position < b.position) {
                        return 1;
                      } else {
                        return -1;
                      }
                    } else {
                      if (Date.parse(a.date) > Date.parse(b.date)) {
                        return 1;
                      } else {
                        return -1;
                      }
                    }
                  })
                  .map((subclass) => {
                    return (
                      <ListGroupItem key={subclass._id}>
                        <Row className="align-items-center">
                          <Col lg>
                            <img
                              alt={subclass.title}
                              style={{ borderRadius: "10px" }}
                              className="shadow"
                              src={subclass.imageUrl}
                              height="200px"
                              width="200px"
                            ></img>
                          </Col>
                          <Col lg className="mt-3 text-center">
                            <h4>{subclass.title}</h4>
                            {lalaClass.showDate && subclass.date && (
                              <p>
                                {format(
                                  new Date(subclass.date),
                                  "EEEE d MMMM HH:mm",
                                  { locale: es }
                                )}
                              </p>
                            )}
                          </Col>
                          {profile.membershipStatus ? (
                            new Date(profile.membershipExpiredDate) <=
                            new Date() ? (
                              <Col className="text-right">
                                <p>Su membresía ha expirado.</p>
                                <Link to="/membersite/payment-info">
                                  <Button className="mainButton">
                                    Renovar Membresía
                                  </Button>
                                </Link>
                              </Col>
                            ) : (
                              <Col lg={4} className="text-right mt-3">
                                <Link
                                  to={{
                                    pathname: `/membersite/subclass/${subclass._id}`,
                                    state: {
                                      subclass: subclass,
                                    },
                                  }}
                                >
                                  <Button className="mainButton">
                                    Ver tema
                                  </Button>
                                </Link>
                              </Col>
                            )
                          ) : (
                            <Col className="text-right">
                              <p>
                                Para participar de esta clase, debes ser Miembro
                                OSHER
                              </p>
                              <Link to="/membersite/payment-info">
                                <Button
                                  className="btn-light"
                                  style={{
                                    border: "1.5px solid rgb(239, 186, 129)",
                                  }}
                                >
                                  Ser miembro
                                  <OsherLogo
                                    className="ml-2"
                                    style={{ height: "30px" }}
                                  />
                                </Button>
                              </Link>
                            </Col>
                          )}
                        </Row>
                      </ListGroupItem>
                    );
                  })}
            </ListGroup>
          </Col>
        )}
      </Row>
    </Container>
  );
};

export default LibraryClassDetail;
