import React, { Component } from "react";
import { Container, Row, Col, Form, Card, Button, Alert } from "react-bootstrap";
import { Link, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getRegisterAction } from "../../actions/authActions";

class Register extends Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  state = {
    email: "",
    password: "",
    confirmPassword: "",
  };

  handleChange = (e) => {
    e.persist();
    this.setState((prevState) => ({
      ...prevState,
      [e.target.id]: e.target.value,
    }));
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.register(this.state);
  };
  render() {
    const { auth, authError } = this.props;
    if (auth.uid) return <Redirect to="/membersite/user" />;
    return (
      <Container fluid style={{ backgroundColor: "rgb(239, 186, 129)" }}>
        <Row style={{ minHeight: "100vh" }}>
          <Col md={{ span: 8, offset: 2 }} className="my-auto">
            <Card className="shadow border-0">
              <Card.Header className="bg-white border-bottom-0">
                <h2>Cree su cuenta</h2>
              </Card.Header>
              <Card.Body>
                <Form
                  noValidate
                  validated={this.state.validated}
                  onSubmit={this.handleSubmit}
                >
                  <Form.Group>
                    <Form.Label className="mainColorText">Correo</Form.Label>
                    <Form.Control
                      required
                      id="email"
                      onChange={this.handleChange}
                      type="email"
                      placeholder="Ingrese su correo"
                    ></Form.Control>
                  </Form.Group>
                  <Form.Group>
                    <Form.Label className="mainColorText">
                      Contraseña
                    </Form.Label>
                    <Form.Control
                      required
                      id="password"
                      onChange={this.handleChange}
                      type="password"
                      placeholder="Ingrese su contraseña"
                    ></Form.Control>
                  </Form.Group>
                  <Form.Group>
                    <Form.Label className="mainColorText">
                      Re-Ingrese contraseña
                    </Form.Label>
                    <Form.Control
                      required
                      id="confirmPassword"
                      onChange={this.handleChange}
                      type="password"
                      placeholder="Ingrese su contraseña nuevamente"
                    ></Form.Control>
                  </Form.Group>
                  {authError ? (
                    <Alert variant="danger">{authError["message"]}</Alert>
                  ) : null}
                  <Form.Group>
                    <Button type="submit" className="mainButton">
                      Registrarse!
                    </Button>
                  </Form.Group>
                  <Form.Group>
                    <p>
                      Ya tenes cuenta? Ingresa{" "}
                      <Link
                        className="mainColorText text-decoration-none"
                        to="/membersite/login"
                      >
                        aqui
                      </Link>
                    </p>
                  </Form.Group>
                </Form>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.firebaseState.auth,
    authError: state.authState.authError,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      register: (credentials) => getRegisterAction(credentials),
    },
    dispatch
  );
export default connect(mapStateToProps, mapDispatchToProps)(Register);
