import { createAction } from "redux-actions";

export const uiActions = {
  SNACKBAR_SUCCESS: createAction("SNACKBAR_SUCCESS", (message) => {
    return {
      message,
    };
  }),
  SNACKBAR_CLEAR: createAction("SNACKBAR_CLEAR"),
};

export const clearSnackbarAction = () => (dispatch) => {
  dispatch(uiActions.SNACKBAR_CLEAR());
};

export const successSnackbarAction = (message) => (dispatch) => {
  dispatch(uiActions.SNACKBAR_SUCCESS(message));
};
