import React, { useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import { getClassesAction } from "../../actions/classActions";
import { getUserAction } from "../../actions/userActions";
import LalaFooter from "../footer/footer";

export default function Follower() {
  const { auth } = useSelector((state) => state.firebaseState);
  const { profile } = useSelector((state) => state.authState);
  const { classes } = useSelector((state) => state.classState);
  const dispatch = useDispatch();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (auth.uid) {
      if (!profile) {
        dispatch(getUserAction(auth.uid));
      }
    }
  }, [auth.uid, dispatch, profile]);

  useEffect(() => {
    if (!classes || classes.length === 0) {
      dispatch(getClassesAction());
    }
  }, [classes, dispatch]);

  if (!auth.uid) return <Redirect to="/membersite/login" />;
  return (
    <Container fluid>
      <Row>
        <Container>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <Row>
            <Col>
              <h3 className="secondaryFont mainColorText display-4">
                Voluntariado
              </h3>
            </Col>
          </Row>
          <br></br>
          <Row>
            <Col md className="text-center">
              <iframe
                className="mobile"
                title="Video Membresia"
                width="100%"
                height="450"
                src="https://player.vimeo.com/video/561600681"
                frameBorder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </Col>
          </Row>
          <Row>
            <Col>
              <br />
              <br />
              <h2 className="title ">Un nuevo espacio para miembros...</h2>
              <p>
                Pensé este espacio para los miembros que sientan como yo, la
                necesidad de comprometerse a vibrar alto y de sostener ese
                estado. Aunque parezca difícil, sé que siempre es posible. Y es
                en esta nueva era, que tenemos que dar el gran paso y salir de
                vivir en la mente para vivir desde el corazón
              </p>
              <p>
                Todos comenzamos este camino de consciencia porque queremos
                sanar nuestras heridas, encontrar bálsamos, sentirnos en paz. Y
                comprender que nadie quiere dañarnos, también es parte del
                proceso.
              </p>
              <p>
                Conocer nuestros dones y aceptarlos es el punto de partida para
                entregarnos a otros y así mejorar aún más nuestra vida.
              </p>
              <p>
                El secreto para sostener este camino de consciencia y mantener
                altas vibraciones, es saber que la vida nos cambia cuando vemos
                las necesidades del otro y decidimos compartir nuestra llama.
              </p>
              <p>
                Aceptar que estamos juntos en este viaje donde cada alma elije
                vivir una experiencia única, nos hace comprender que todo fue
                creado por nosotros y que todo es perfecto.
              </p>
              <p>
                Reconocer que el otro tiene su propio ritmo y sigue su propio
                proceso, es muy importante para compartir nuestra energía sin
                perdernos. Por el contrario, nos permitirá convertirnos en ese
                canal que todos vinimos a ser y recibir de manera constante la
                energía de la fuente.
              </p>
            </Col>
          </Row>
          {/* {classes && classes.some((element) => element.isTrainee) && (
            <Row>
              <Col>
                {classes.find((element) => element.isTrainee).subclasses[0]
                  .meetingLink ? (
                  <NavLink
                    rel="noopener"
                    href={
                      classes.find((element) => element.isTrainee).subclasses[0]
                        .meetingLink
                    }
                    target="_blank"
                  >
                    <Button className="mainButton mt-5">
                      Ingresar a la Clase
                    </Button>
                  </NavLink>
                ) : (
                  <h3>Cuando la clase esté lista, el botón aparecerá aquí</h3>
                )}
              </Col>
            </Row>
          )} */}
        </Container>
      </Row>
      <br />
      <br />
      <br />
      <br />
      <LalaFooter />
    </Container>
  );
}
