import React from "react";
import { Container, Row } from "react-bootstrap";
import qs from "qs";
const SuccessPayment = (props) => {
  console.log(props);
  return (
    <Container className="mt-5 pt-5">
      <Row>
        <h1>Gracias por su compra</h1>
        <p>
          {
            qs.parse(props.location.search, { ignoreQueryPrefix: true })
              .collection_status
          }
        </p>
      </Row>
    </Container>
  );
};

export default SuccessPayment;
