import React from "react";
import { Card, Button, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
const StoreItem = ({ image, title, description, secondaryDescription }) => {
  return (
    <Card className="text-center border-0 text-light shadow h-100">
      <Card.Img
        variant="top"
        src={image}
        height="300"
        style={{ objectFit: "cover" }}
      />
      <Card.Body>
        <Card.Title className="text-dark">{title}</Card.Title>
        <Row className="h-100">
          <Col>
            <Card.Text className="text-dark">{description}</Card.Text>
          </Col>
        </Row>
      </Card.Body>
      <Card.Footer className="border-0">
        <Link
          to={{
            pathname: `/store/${title}`,
            state: {
              title: title,
              description: description,
              secondaryDescription: secondaryDescription,
              image: image,
            },
          }}
        >
          <Button className="mainButton">Ver Más</Button>
        </Link>
      </Card.Footer>
    </Card>
  );
};

export default StoreItem;
